import React from 'react';
import '../../App.css';
import Footer from '../Footer';
import SortingVisualizer from '../SortingVisualizer/SortingVisualizer.jsx'
function Viz() {
    return (
      <>
        <SortingVisualizer>

        </SortingVisualizer>
        <Footer></Footer>
      </>
    );
  }


export default Viz;

