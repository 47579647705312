import React from 'react';
import '../../App.css';
import Footer from '../Footer';
import '../HeroSection.css';
import '../pages/generalformat.css'
import SortingVisualizer from '../SortingVisualizer/SortingVisualizer.jsx'
function School() {
    return (
      <>
        <div className='hero-container'>
            <h1>Schooling</h1>
        
        </div>
        <div className ="general-container">
            <h1>
            Santa Clara University
            </h1>
            <h2>
                Major: Computer Science
            </h2>
            <h2>
            September 2018-present
            </h2>
            <p>
            Relevant Coursework:
            Object-Oriented Programming, Data Structure, Logic Design, Theory of Algorithms, Operating Systems, Theory
            of Automata, Embedded Systems, Calculus 1-4, Linear Algebra, Probability and Statistics, Discrete Math, Data Science, Networks, Computer Graphics
            </p>
            <p>
            Recent Coding Experience: Participation in the Santa Clara University Hack for Humanity 2020. Worked with a team of 5 to create a program that used artificial intelligence to detect the brand of a product that might be in a grocery store and gave the user information about the brand/company and their carbon footprint. 


            </p>
            <h1>
            Seattle Preparatory School
            </h1>
            <h2>
            Graduate, June 2018
            </h2>
            <p>
            Coding Competitions: Competed in numerous coding competitions in high school, working as a team to figure out solutions to a problem set. Skills learned were budgeting time, sharing resources, and communicating to complete problem sets within a time limit.

    
            </p>
        </div>
        <Footer></Footer>
      </>
    );
  }


export default School;