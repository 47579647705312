import React from 'react';
import './Footer.css';
import { Button } from './Button';
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <div className='footer-container'>
      <div class='footer-links'>
        <div className='footer-link-wrapper'>
          <div class='footer-link-items'>
            <h2>Learn More</h2>
            <Link to='/'>Home</Link>
            <Link to='/Work'>Work Experience</Link>
            <Link to='/School'>Schooling</Link>
            <Link to='/Code'>Code Experience</Link>
            <Link to='/Viz'>Sorting</Link>
          </div>
        </div>
        
          <div class='footer-link-items'>
            <h2>Social Media</h2>
            <a target="_blank" href="https://www.youtube.com/watch?v=dQw4w9WgXcQ">Twitter</a>
            <a target="_blank" href="https://www.youtube.com/watch?v=dQw4w9WgXcQ">Facebook</a>
            
            <a target="_blank" href="https://www.youtube.com/watch?v=dQw4w9WgXcQ">YouTube</a>
            <a target="_blank" href="https://www.youtube.com/watch?v=dQw4w9WgXcQ">Instagram</a>
            </div>
        
      </div>
      <section class='social-media'>
        <div class='social-media-wrap'>
          <div class='footer-logo'>
            <Link to='/' className='social-logo'>
              Dominic Teplicky
              
            </Link>
          </div>
          <small class='website-rights'>created by Dominic Teplicky</small>
          <div class='social-icons'>
            
            <Link
              class='social-icon-link instagram'
              to='/'
              target='_blank'
              aria-label='Instagram'
            >
              <i class='fab fa-instagram' />
            </Link>
            <Link
              class='social-icon-link twitter'
              to='/'
              target='_blank'
              aria-label='Twitter'
            >
              <i class='fab fa-twitter' />
            </Link>
            <Link
              class='social-icon-link twitter'
              to='/'
              target='_blank'
              aria-label='LinkedIn'
            >
              <i class='fab fa-linkedin' />
            </Link>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Footer;
