import React from 'react';
import './Cards.css';
import CardItem from './CardItem';

function Cards() {
  return (
    <div className='cards'>
      <h1>Learn More About Me!</h1>
      <div className='cards__container'>
        <div className='cards__wrapper'>
          <ul className='cards__items'>
            <CardItem
              src="images/IMG_2692.jpg"
              text='Learn About My Work Experience'
              label='Work'
              path='/Work'
            />
            <CardItem
              src='images/Mission_Santa_Clara.jpeg'
              text='Learn About My Schooling'
              label='School'
              path='/School'
            />
          </ul>
          <ul className='cards__items'>
            
            <CardItem
              src='images/code.jpg'
              text='Learn About My Coding Experience'
              label='Code'
              path='/Code'
            />
            <CardItem
              src='images/domBB.jpg'
              text='Sorting Visualization'
              label='Sort'
              path='/Viz'
            />
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Cards;
