export function getMergeSortAnimations(array) {
    const animations = [];
    if (array.length <= 1) return array;
    const auxiliaryArray = array.slice();
    mergeSortHelper(array, 0, array.length - 1, auxiliaryArray, animations);
    return animations;
  }
  
  function mergeSortHelper(
    mainArray,
    startIdx,
    endIdx,
    auxiliaryArray,
    animations,
  ) {
    if (startIdx === endIdx) return;
    const middleIdx = Math.floor((startIdx + endIdx) / 2);
    mergeSortHelper(auxiliaryArray, startIdx, middleIdx, mainArray, animations);
    mergeSortHelper(auxiliaryArray, middleIdx + 1, endIdx, mainArray, animations);
    doMerge(mainArray, startIdx, middleIdx, endIdx, auxiliaryArray, animations);
  }
  
  function doMerge(
    mainArray,
    startIdx,
    middleIdx,
    endIdx,
    auxiliaryArray,
    animations,
  ) {
    let k = startIdx;
    let i = startIdx;
    let j = middleIdx + 1;
    while (i <= middleIdx && j <= endIdx) {
      
      animations.push([i, j]);
      
      animations.push([i, j]);
      if (auxiliaryArray[i] <= auxiliaryArray[j]) {
       
        animations.push([k, auxiliaryArray[i]]);
        mainArray[k++] = auxiliaryArray[i++];
      } else {
       
        animations.push([k, auxiliaryArray[j]]);
        mainArray[k++] = auxiliaryArray[j++];
      }
    }
    while (i <= middleIdx) {
      
      animations.push([i, i]);
      
      animations.push([i, i]);
      
      animations.push([k, auxiliaryArray[i]]);
      mainArray[k++] = auxiliaryArray[i++];
    }
    while (j <= endIdx) {
      
      animations.push([j, j]);
      
      animations.push([j, j]);
      
      animations.push([k, auxiliaryArray[j]]);
      mainArray[k++] = auxiliaryArray[j++];
    }
  }


  export function getQuickSortAnimations(array) {
    const animations = [];
    if (array.length <= 1) return array;

    quickSortHelper(array, 0, array.length-1, animations)
    return animations;
  }
  
  function quickSortHelper(
    arr,
    low,
    high,
    animations,
  ) {
    
    if (low < high)
    {
        var pi = doQuick(arr, low, high,animations);
 
    
        quickSortHelper(arr, low, pi - 1,animations);
        quickSortHelper(arr, pi + 1, high,animations);
    }
  }
  function swap(items, leftIndex, rightIndex){
    var temp = items[leftIndex];
    items[leftIndex] = items[rightIndex];
    items[rightIndex] = temp;
}

  function doQuick(
    arr,
    low,
    high,
    animations,
  ) {

    var pivot = arr[high];
      
    
    var i = (low - 1);

    for(var j = low; j <= high - 1; j++)
    {
          
     
        if (arr[j] < pivot)
        {
              
           
            i++;
            animations.push([i,j])
            animations.push([i,j])
            animations.push([i,arr[j]])
            animations.push([j,arr[i]])
            swap(arr, i, j);
            
        }
    }
    animations.push([i+1,high])
    animations.push([i+1,high])
    animations.push([i+1,arr[high]])
    animations.push([high,arr[i+1]])
    swap(arr, i + 1, high);
    return (i + 1);
  }



  export function getHeapSortAnimations(array) {
    const animations = [];
    if (array.length <= 1) return array;
    heapSortHelper(array,animations)
    return animations;
  }
  
  function heapSortHelper(
    arr,
    animations,
  ) {
      var n = arr.length;
  
      
      for (var i = n / 2 - 1; i >= 0; i--)
      doHeap(arr, n, i,animations);

     
      for (var i = n - 1; i > 0; i--) {
          
          animations.push([i,0])
          animations.push([i,0])
          animations.push([i,arr[0]])
          animations.push([0,arr[i]])
          var temp = arr[0];
          arr[0] = arr[i];
          arr[i] = temp;
          
          
          doHeap(arr, i, 0,animations);
      }
  }

  function doHeap(
    arr,
    n,
    i,
    animations,
  ) {
      var largest = i;
      var l = 2 * i + 1; 
      var r = 2 * i + 2; 

    
      if (l < n && arr[l] > arr[largest])
          largest = l;


      if (r < n && arr[r] > arr[largest])
          largest = r;


      if (largest != i) {
          
          animations.push([i,largest])
          animations.push([i,largest])
          animations.push([i,arr[largest]])
          animations.push([largest,arr[i]])
          var swap = arr[i];
          arr[i] = arr[largest];
          arr[largest] = swap;
 
          doHeap(arr, n, largest,animations);
      }

  }

  export function getBubbleSortAnimations(array) {
    const animations = [];
    if (array.length <= 1) return array;
    bubbleSortHelper(array,array.length,animations)
    return animations;
  }
  
  function bubbleSortHelper(
    arr,
    n,
    animations,
  ) {
      var i, j;
      for (i = 0; i < n-1; i++)
      {
          for (j = 0; j < n-i-1; j++)
          {
              if (arr[j] > arr[j+1])
              {
                animations.push([j,j+1])
                animations.push([j,j+1])
                animations.push([j,arr[j+1]])
                animations.push([j+1,arr[j]])
                
                swap(arr,j,j+1);
               
              }
          }
       
      }
  }