import React from 'react';
import Navbar from './components/Navbar';
import './App.css';
import Home from './components/pages/Home';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Viz from './components/pages/Viz';
import Work from './components/pages/Work';
import School from './components/pages/School';
import Contact from './components/pages/Contact';
import Code from './components/pages/Code';

function App() {
  return (
    <>
      <Router>
        <Navbar />
        <Switch>
          <Route path='/' exact component={Home} />
          <Route path='/Viz' exact component={Viz} />
          <Route path='/Work' exact component={Work} />'
          <Route path='/School' exact component={School} />'
          <Route path='/Code' exact component={Code} />'
          <Route path='/Contact' exact component={Contact} />'
         
        </Switch>
      </Router>
    </>
  );
}

export default App;
