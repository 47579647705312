import React from 'react';
import '../../App.css';
import Footer from '../Footer';
import '../HeroSection.css';
import '../pages/generalformat.css'
function Contact() {
    return (
      <>
        <div className='hero-container'>
            <h1>Contact Information</h1>
            <p>Dteplicky@scu.edu</p>
            
        
        </div>
        <Footer></Footer>
      </>
    );
  }


export default Contact;