import React from 'react';
import '../../App.css';
import Footer from '../Footer';
import '../HeroSection.css';
import '../pages/generalformat.css'
import SortingVisualizer from '../SortingVisualizer/SortingVisualizer.jsx'
function Work() {
    return (
      <>
        <div className='hero-container'>
            <h1>Work Experience</h1>
        
        </div>
        <div className ="general-container">
            <h1>
            Front End Assistant - South Bend Bistro
            </h1>
            <h2>
            May 2020-September 2020
            </h2>
            <p>
            Assisted ownership manage the front 
            end of a busy resort area restaurant. Helped manage online and telephone reservations and all takeout orders, seated guests, bussed tables, and delivered food to tables. Assisted with training and management of other bussers and food runners. Gained further experience interacting with customers in a friendly and professional manner and working in a high-pressure environment. 
            </p>
            <h1>
            Sales Intern - QBSI: Xerox	
             </h1>
            <h2>
            June 2019-September 2019
            </h2>
            <p>
            Interned with the Seattle sales team. Assisted two sales representatives with prospect research, organization of databases, and creating a schedule for visiting prospects. Worked in the field during sales meetings as well as deliveries of machines. Gained experience interfacing with clients and working in a professional business environment.
            </p>
            <h1>
            Busser/Host - Palomino Bellevue			
             </h1>
            <h2>
            June 2018-September 2018
            </h2>
            <p>
            Greeted customers and distributed food delivery service orders.  Seated customers and helped provide excellent customer service in a fast-paced dining environment. Helped manager with organizational tasks.
            </p>
            <h1>
            Courtesy Clerk - Town & Country Market Lakemont	
            </h1>
            <h2>
            August 2016-September 2017
            </h2>
            <p>
            Assisted customers by bagging and carrying out groceries, helping them find items in the store, and interacting in a friendly and courteous way.  Assisted store staff and management by cleaning restrooms, disposing of refuse, handling natural gas tanks, retrieving shopping carts, cleaning store and other miscellaneous duties.      
            </p>
        </div>
        <Footer></Footer>
      </>
    );
  }


export default Work;