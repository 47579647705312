import React from 'react';
import '../../App.css';
import Footer from '../Footer';
import '../HeroSection.css';
import '../pages/generalformat.css'
function Code() {
    return (
      <>
        <div className='hero-container'>
            <h1>Programming Languages</h1>
        </div>
        <div className ="general-container">
            <h1>
                Proficient Languages
            </h1>
            <p>
                C++/C
            </p>
            <p>
                Python
            </p>
            <p>
                Java
            </p>
            <h1>
                Limited Experience
            </h1>
            <p>
                JavaScript
            </p>
            <p>
                HTML
            </p>
            <p>
                Assembly
            </p>
            <p>
                CSS
            </p>
            <h1>
                APIs I know
            </h1>
            <p>
                Pygame
            </p>
            <p>
                NumPy
            </p>
            <p>
                Pandas
            </p>
            <p>
                OpenGL
            </p>
            <p>
                Seaborn
            </p>
            <p>
                Mathplotlib
            </p>
            <p>
                React
            </p>
            <p>
                SKLearn
            </p>
        </div>
        <Footer></Footer>
      </>
    );
  }


export default Code;